<template>
  <div class="text-left">
    <div class="mt-6 flex w-full text-c24 font-c6 bloc bg-f5 p-6 pr-0 zone1">
      Statut de la commande
    </div>

    <div class="bloc bg-white zone2 p-6 text-left">
      <div v-for="(item, index) in status" :key="index" class="mt-4">
        <steep :donnes="item" :index="index" :position="allSteep.length + 1"/>
      </div>
    </div>
  </div>
</template>

<script>
import user from '../../../assets/icons/user-circle.svg'
import imprim from '../../../assets/icons/imprim.svg'
import send from '../../../assets/icons/send.svg'
import avoir from '../../../assets/icons/avoir.svg'
import priseEncharge from '../../../assets/icons/priseEnCharge.svg'
import steep from './steepTemplate'

export default {
  name: "Index",

  components: {
    steep
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        user,
        imprim,
        send,
        avoir,
        priseEncharge
      },
      devis: null,
      activePaye: false,
      position: 2,
      status: [],
      allSteep: []
    }
  },

  mounted () {
    let donnes = this.donnes.steps
    this.allSteep = this.donnes.steps

    console.log(donnes)

    for (let item in donnes) {
      let body = donnes[item]
      if (donnes[item].type === 'ESTIMATE_VALIDATED'){
        body.note = ''
        body.error = false
        body.title = 'Elaboration du devis'
        body.agence = 'AGENCE'
      }

      if (this.donnes.estimate.meta){
        body.note = this.donnes.estimate.meta.note
      }

      if (donnes[item].type === 'COORDINATION_REJECT'){
        body.note = donnes[item].note
        body.error = true
        body.title = 'Vérification de conformité'
        body.agence = 'Coordination'
      }

      if (donnes[item].type === 'COORDINATION_VALIDATION'){
        body.note = donnes[item].note
        body.error = false
        body.title = 'Vérification de conformité'
        body.agence = 'Coordination'
      }

      if (donnes[item].type === 'TECHNIQUE_REJECT'){
        body.note = donnes[item].note
        body.error = true
        body.title = 'Validation technique'
        body.agence = 'Technique'
      }

      if (donnes[item].type === 'TECHNIQUE_VALIDATION'){
        body.note = donnes[item].note
        body.error = false
        body.title = 'Validation technique'
        body.agence = 'Technique'
      }
      this.status.push(body)
    }

  },

  methods: {
    retourAdd () {
    },

    retourPaie () {
      this.$emit('payement', true)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: white;
}

.bloc {
  filter: drop-shadow(0px 4.08108px 10px rgba(0, 0, 0, 0.1));
  z-index: 1;
}
.zone1{
  border-radius: 6px 6px 0px 0px;
}
.zone2{
  border-radius: 0px 0px 6px 6px;
}

</style>
