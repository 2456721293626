<template>
  <div
    class="text-left p-4 cursor-pointer flex items-center"
    :style="{'height': height, 'border': border, 'color': color, 'border-radius': radius, 'background-color': background}"
    @click="openUpload()"
  >
    <div class="flex items-center w-full">
      <div class="w-1/5">
        <icon
          :data="icon"
          height="30"
          width="30"
          class="cursor-pointer text-center"
          original
        />
      </div>

      <div class="ml-2 w-4/5 font-c5 text-c18">
          {{ label }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Index",

  components: {
  },
  props: {
    icon: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    background: {
      type: String,
      default: 'black'
    },
    border: {
      type: String,
      default: 'none'
    },
    radius: {
      type: String,
      default: '0px'
    },
    height: {
      type: String,
      default: '90px'
    },
    color: {
      type: String,
      default: '#fff'
    },
  },

  data () {
    return {
    }
  },

  created () {
  },

  methods: {
    openUpload () {
      this.$emit('oga', true)
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}


</style>
