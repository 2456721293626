<template>
  <div class="text-left">
    <div class="flex justify-center">
      <div class="w-1/2 pl-6 mb-6">
        <upload
            v-if="donnes.ordinanceUrls !== null"
            :icon="icons.ordonnance"
            background="#FBFBFB"
            border="1px dashed #C4C4C4"
            radius="5px"
            height="68px"
            label="Ordonnance"
            color="#606060"
            @oga="document(donnes.ordinanceUrls[0])"
        />
      </div>

      <div class="w-1/2 pl-6 mb-6" v-for="(item, index) in donnes.documentUrls" :key="index">
        <upload
            :icon="icons.document"
            background="#FBFBFB"
            height="68px"
            border="1px dashed #C4C4C4"
            radius="5px"
            :label="'Document ' + (index + 1)"
            color="#606060"
            @oga="document(item)"
        />
      </div>

      <div class="w-full text-center text-60 text-c14" v-if="donnes.documentUrls === null && donnes.ordinanceUrls === null">
        Aucun document ajouter à cette commande
      </div>

    </div>
  </div>
</template>

<script>
import document from '../../../assets/icons/doc.svg'
import ordonnance from '../../../assets/icons/file.svg'
import upload from './showDocument'
import user from '../../../assets/icons/user-circle.svg'

export default {
  name: "Index",

  components: {
    upload,
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        document,
        ordonnance
      },
    }
  },

  created () {
  },

  methods: {
    document (item) {
      window.open(item, "_blank");
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.inputo {
  background: #FFFFFF;
  border: 0.5px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 5px;
  height: 50px;
}

</style>
